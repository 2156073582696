import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    dimmed:true,
    engineRemove:false,
    audio: {
      playOpeningLoop: false,
      currentSource: null,
      sources: [["live.mp3"], ["world.mp3"], ["lovers.mp3"]],
      tracks: [null, null],
      currentTrack: 0,
    },
    sound: {
      opening: null,
      openingId: null,
      ticket: {
        g: null,
        n: null,
        s: null,
      },
      gallery1: { main: null, second: null },
      gallery2: { main: null, second: null },
      gallery3: { main: null, second: null },
    },
    visited: [false, false, false],
    currentGallery: -1,
    gallery1: {
      mapDialog: false,
      currentSection: 0,
      currentMap: -1,
      direction: "next",
      showArrows: false,
    },
    gallery2: {
      currentSection: 0,
    },
    gallery3: {
      currentSection: 0,
      showArrows: false,
    },
  },
  mutations: {
    updateDimmed(state,val){
      state.dimmed = val;
    },
    updateEngineRemove(state,val){
      state.engineRemove = val;
    },
    updateOpeningLoop(state, val) {
      state.audio.playOpeningLoop = val;
    },
    updateEngine(state, val) {
      state.engine = val;
    },
    updateMapDialog(state, val) {
      state.gallery1.mapDialog = val;
    },
    updateCurrentGallery(state, val) {
      state.currentGallery = val;
    },
    updateCurrentSource(state, val) {
      state.audio.track.currentSource = val;
    },
    updateTracks(state, val) {
      state.audio.tracks[val[0]] = val[1];
    },
    updateSoundGallery2(state, val) {
      state.sound.gallery2[val.key] = val.value;
    },
    updateVisited(state, val) {
      state.visited[val] = true;
    },
    updateCurrentMap(state, val) {
      state.gallery1.currentMap = val;
    },
    updateCurrentSection(state, val) {
      state.gallery2.currentSection = val;
    },
    updateGallery3CurrentSection(state, val) {
      state.gallery3.currentSection = val;
    },
    // updateSoundOpening(state, val) {
    //   state.sound.opening = val;
    // },
    updateShowArrows(state, val) {
      state.gallery3.showArrows = val;
    },
  },
  actions: {},
  modules: {},
});
