<template>
  <v-app>
    <v-main>
      <transition name="fade">
        <router-view />
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import { Howl, Howler } from "howler";

export default {
  name: "App",
  data: () => ({
    myMusic: null,
    gallery1Music: null,
    volumes: [1, 1],
    //
  }),
  methods: {
    playSound() {
      this.myMusic = new Howl({
        src: ["music/opening-loop.mp3"],
        loop: true,
        onplayerror: () => {
          this.myMusic.once("unlock", () => {
            this.myMusic.stop();
            this.myMusic.play();
          });
        },
      });
      this.myMusic.once("load", () => {
        this.myMusic.stop();
        this.myMusic.play();
      });
    },
  },
  destroyed() {
    this.myMusic?.once("fade", () => {
      this.myMusic.unload();
    });
    this.myMusic?.fade(this.myMusic.volume(), 0, 1000);
  },
  mounted() {},
  computed: {
    playOpeningLoop() {
      return this.$store.state.audio.playOpeningLoop;
    },
    currentSource() {
      return this.$store.state.audio.currentSource;
    },
    sources() {
      return this.$store.state.audio.sources;
    },
    tracks() {
      return this.$store.state.audio.tracks;
    },
    currentTrack() {
      return this.$store.state.audio.currentTrack;
    },
    currentGallery() {
      return this.$store.state.currentGallery;
    },
  },
  watch: {
    currentGallery(val) {
      if (val == 0) {
        return;
      } else if (val == 1) {
        return;
      } else if (val == 2) {
        return;
      }
    },
    playOpeningLoop(val) {
      if (val) {
        this.playSound();
      } else {
        this.myMusic?.once("fade", () => {
          this.myMusic.unload();
        });
        this.myMusic?.fade(this.myMusic.volume(), 0, 1000);
      }
    },
  },
};
</script>

<style lang="scss">
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
html,
body,
.v-application {
  p {
    line-height: 1.7rem;
  }
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
  color: $color--brown;
  font-weight: 300;
}
h2 {
  margin-bottom: 1rem;
  line-height: 2rem;
  font-weight: 300;
}
p {
  font-size: 1rem;
  line-height: 1.875rem;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre-wrap;
}
q {
  quotes: "\201C""\201D""\2018""\2019";
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 14px;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

html,
body {
  background: $color--bg;
  overflow: hidden;
}
.v-application--wrap {
  z-index: 9;
}
.theme--dark.v-application {
  background: $color--bg !important;
}
.img-responsive {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.cursor-pointer {
  cursor: pointer;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 5s linear infinite;
  -moz-animation: rotating 5s linear infinite;
  -ms-animation: rotating 5s linear infinite;
  -o-animation: rotating 5s linear infinite;
  animation: rotating 5s linear infinite;
}

.rotating-2x {
  -webkit-animation: rotating 8s linear infinite;
  -moz-animation: rotating 8s linear infinite;
  -ms-animation: rotating 8s linear infinite;
  -o-animation: rotating 8s linear infinite;
  animation: rotating 8s linear infinite;
}

.adc-container {
  * {
    font-size: 0.6rem;
    line-height: 0.85rem !important;
  }
}

.btn-close-dialog {
  top: 2rem;
  left: 2rem;
  &.top-left {
    top: 0;
    left: 0;
  }
}

// Gallery 2
.fade-out {
  opacity: 0;
}

.btn-menu {
  position: fixed;
  z-index: 999;
  top: 0.7rem;
  left: 0.7rem;
  cursor: pointer;
  margin: 1rem;
}

.right-toolbar {
  position: fixed;
  z-index: 999;
  top: 2rem;
  right: 2rem;
}

.subtitle {
  width: 200px;
}
.section-btn-container {
  display: flex;
  flex-direction: row;
  .section-btn {
    cursor: pointer;
    width: 100%;
    height: 3px;
    padding: 0.5rem 0.2rem;
    .btn-color {
      width: 100%;
      height: 3px;
      background: $color--brown;
      opacity: 0.3;
      transition: all 0.3s ease-out;
    }
    &.active .btn-color {
      opacity: 1;
    }
  }
  .section-btn:first-child {
    margin-left: 0;
  }
  .section-btn:last-child {
    margin-right: 0;
  }
}

.pointer-events-none {
  pointer-events: none;
}

// Gallery 2 - instruments
.btn-controls {
  .btn-next,
  .btn-prev {
    width: 100px;
    height: 100px;
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 1;
    }
  }
  .btn-prev {
    left: 0;
    transform: rotate(180deg);
  }
  .btn-next {
    right: 0;
  }
}
.btn-next svg,
.btn-prev svg {
  fill: $color--brown;
}
// .btn-controls-vertical{
//   position:fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   margin:auto;
//   z-index:9;
// }

.btn-vertical {
  width: 100px;
  height: 100px;
  padding: 1rem;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  opacity: 0.5;
  transition: opacity 0.3s ease-out;
  &:hover {
    opacity: 1;
  }
  &.position-absolute {
    position: absolute;
  }
  &.position-relative {
    position: relative;
  }
}
.btn-next-vertical {
  top: auto;
  bottom: 0;
  transform: rotate(90deg);
}

.btn-prev-vertical {
  top: 0;
  bottom: auto;
  transform: rotate(-90deg);
}

.btn-controls-disable {
  pointer-events: none;
  opacity: 0.3;
}

.section-wrapper {
  position: relative;
  z-index: 9;
  transition: opacity 0.3s ease-out;
}
.section-intro {
  text-align: center;
  margin: 0 auto;
  max-width: 850px;
}
.section-title {
  margin: 0 auto;
  margin-bottom: 5rem;
  padding-top: 25vh;
  max-width: 600px;
}
.section-panel-text {
  width: 80%;
  padding: 0 1.5rem;
  text-align: center;
  margin: 0 auto;
  p {
    margin: 2rem 0;
    line-height: 1.7rem;
  }
}
.section-content-spacer {
  height: 100vh;
  pointer-events: none;
  &.gallery1-spacer {
    height: 1vh;
  }
  &.gallery3-spacer {
    height: 1vh;
  }
  &.spacer-not-ready {
    height: 0vh;
  }
}
.btn-controls {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.inst-group {
  position: absolute;
  z-index: 9;
}
.inst-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-out;
}
.dimmed {
  opacity: 0.3;
}
.inst-player {
  width: 100vw;
  height: auto;
  z-index: 1;
  pointer-events: none;
}
.inst-group {
  opacity: 0;
  &:hover .inst-item {
    opacity: 0.4;
  }
  .inst-item {
    position: absolute;
    width: 7vw;
    cursor: pointer;
    transition: opacity 0.3s ease-out;
    &:hover {
      opacity: 1;
    }
  }
}
.inst-group-1 {
  .inst-item:nth-child(1) {
    top: -10vw;
    left: 17vw;
  }
  .inst-item:nth-child(2) {
    width: 6.5vw;
    top: -15vw;
    left: 11.2vw;
  }
  .inst-item:nth-child(3) {
    width: 6.3vw;
    top: -11.5vw;
    left: 5vw;
  }
  .inst-item:nth-child(4) {
    // width: 5.2vw;
    top: -11.5vw;
    left: -2.3vw;
  }
  .inst-item:nth-child(5) {
    width: 5vw;
    top: -11.5vw;
    left: -7.5vw;
  }
  .inst-item:nth-child(6) {
    width: 6.3vw;
    top: -8.35vw;
    left: -14.5vw;
  }
  .inst-item:nth-child(7) {
    width: 6.8vw;
    top: -14.5vw;
    left: -20.9vw;
  }
  .inst-item:nth-child(8) {
    width: 5.5vw;
    top: -6.5vw;
    left: -26.6vw;
  }
}

.inst-group-2 {
  .inst-item:nth-child(1) {
    width: 5.3vw;
    top: -10vw;
    left: -27vw;
  }
  .inst-item:nth-child(2) {
    width: 4.5vw;
    top: -8vw;
    left: -11.8vw;
  }
  .inst-item:nth-child(3) {
    width: 5.3vw;
    top: -6.5vw;
    left: 4.5vw;
  }
  .inst-item:nth-child(4) {
    top: -11.5vw;
    left: 13.45vw;
    width: 5.6vw;
  }
  .inst-item:nth-child(5) {
    width: 5vw;
    top: -5.5vw;
    left: 20.5vw;
  }
}

.inst-group-3 {
  .inst-item:nth-child(1) {
    width: 5.3vw;
    top: -5vw;
    left: 0.8vw;
  }
  .inst-item:nth-child(2) {
    width: 8.8vw;
    top: -18vw;
    left: -7.5vw;
  }
  .inst-item:nth-child(3) {
    width: 17.3vw;
    top: 8.5vw;
    left: 13.6vw;
  }
  .inst-item:nth-child(4) {
    top: 2.5vw;
    left: -14.55vw;
    width: 5.6vw;
  }
  .inst-item:nth-child(5) {
    width: 3.5vw;
    top: 5.8vw;
    left: -24.6vw;
  }
}

.inst-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;

  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // justify-items: center;
  // align-items: center;
  z-index: 9;

  overflow: auto;
}

.inst-modal-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.2);
  }
}
.inst-modal-content-container {
  // width: 100%;
  height: 100%;
  display: flex;
  position: relative;

  .btn-close {
    cursor: pointer;
    position: absolute;
    right: 10%;

    padding: 3rem;
    z-index: 9999;
  }
}
.inst-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: auto;

  .inst-image {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  .inst-name {
    font-size: 1.6rem;
  }
  .inst-content {
    // max-width: 70vw;
    width: 80%;
    max-width: 680px;
    padding: 1rem 2rem 2rem;
  }
}

// Gallery 2 - compare
// .slick-container.fullscreen .slick-slide{
.slide-container--text {
  width: 100vw;
  height: 100vh;
}
.slide-container--text {
  display: flex;
  align-items: center;
  justify-content: center;
  .text-container {
    width: 100%;
    padding: 0 2rem;
    max-width: 600px;
    text-align: center;
  }
}
.slick-prev,
.slick-next {
  color: #fff;
}

.slide-item {
  position: relative;
  display: flex !important;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}
.slide-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

// Gallery 2 - iconography
.image-caption-container {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.6);
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
}
.caption-container {
  max-width: 500px;
  padding: 1rem 2rem;
}
.caption-title {
  color: $color--brown;
}
.caption-content {
}

// Gallery 1 - map
.map-base {
  opacity: 0;
}
.map-wrapper {
  outline: none;
}
// .map-wrapper{
//   opacity:0;
// }

@media only screen and (max-width: 960px) {
  .inst-modal-content-container {
    .btn-close {
      position: fixed;
      padding: 1.5rem;
      top: 64px;
      left: 10px;
      right: auto;
    }
  }
  .btn-about-container.btn-about-us {
    top: 20vh !important;
  }
  .v-dialog > .v-card > .v-card__text {
    margin-top: 3rem;
  }
  .corridor-container {
    // top: 50%;
    // transform: translateY(-50%);
  }
  #menu-item-0 {
    max-width: 58vw !important;
    top: 30vh !important;
    left: -7vw !important;
  }
  #menu-item-2 {
    top: 11vh !important;
  }
  #menu-item-3 {
    max-width: 200px !important;
    top: 68vh !important;
    left: 24vw !important;
  }
  .map-menu-layer:nth-child(8) {
    bottom: 80vh !important;
  }
  .map-menu-layer:nth-child(7) {
    bottom: 73vh !important;
  }
}

@media only screen and (max-width: 480px) {
  .btn-vertical {
    width: 80px;
    // display: flex;
    &.btn-prev {
      top: 50px;
    }
  }
}
</style>
