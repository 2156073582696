import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { title: "Entrance" },
    component: Home,
  },
  {
    path: "/ticket",
    name: "Ticket",
    meta: { title: "Ticket" },
    component: () => import("../views/Ticket.vue"),
  },
  {
    path: "/museum-exhibition",
    name: "Museum",
    meta: { title: "Museum Exhibition" },
    component: () => import("../views/Museum.vue"),
  },
  {
    path: "/gallery-exploratorium",
    name: "Gallery1",
    component: () => import("../views/Gallery1.vue"),
    children: [
      {
        path: "",
        name: "Gallery1-Home",
        meta: { title: "Gallery Exploratorium" },
        component: () => import("../views/Gallery1/Home.vue"),
      },
      {
        path: "maps",
        name: "Maps",
        meta: { title: "Gallery Exploratorium - Map Menu" },
        component: () => import("../views/Gallery1/Maps.vue"),
      },
      {
        path: "map",
        name: "Map",
        component: () => import("../views/Gallery1/Map.vue"),
        children: [
          {
            path: "base",
            name: "Map-0",
        meta: { title: "Gallery Exploratorium - Map 0" },
        component: () => import("../views/Gallery1/Map-0-base.vue"),
          },
          {
            path: "cosmos",
            name: "Map-1",
        meta: { title: "Gallery Exploratorium - Map 1" },
        component: () => import("../views/Gallery1/Map-1-star.vue"),
          },
          {
            path: "steppe",
            name: "Map-2",
        meta: { title: "Gallery Exploratorium - Map 2" },
        component: () => import("../views/Gallery1/Map-2-steppe.vue"),
          },
          {
            path: "terrains",
            name: "Map-3",
        meta: { title: "Gallery Exploratorium - Map 3" },
        component: () => import("../views/Gallery1/Map-3-lands.vue"),
          },
          {
            path: "rivers",
            name: "Map-4",
        meta: { title: "Gallery Exploratorium - Map 4" },
        component: () => import("../views/Gallery1/Map-4-rivers.vue"),
          },
          {
            path: "transoxania",
            name: "Map-5",
        meta: { title: "Gallery Exploratorium - Map 5" },
        component: () => import("../views/Gallery1/Map-5-transoxiana.vue"),
          },
          {
            path: "cities",
            name: "Map-6",
        meta: { title: "Gallery Exploratorium - Map 6" },
        component: () => import("../views/Gallery1/Map-6-cities.vue"),
          },
          {
            path: "museums",
            name: "Map-7",
        meta: { title: "Gallery Exploratorium - Map 7" },
        component: () => import("../views/Gallery1/Map-7-museums.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/gallery-laboratorium",
    name: "Gallery2",
        meta: { title: "Gallery Laboratorium" },
        component: () => import("../views/Gallery2.vue"),
    children: [
      {
        path: "",
        name: "instruments",
        meta: { title: "Gallery Laboratorium - instruments" },
        component: () => import("../views/Gallery2/instruments.vue"),
      },
      {
        path: "table",
        name: "compare",
        meta: { title: "Gallery Laboratorium - table" },
        component: () => import("../views/Gallery2/compare.vue"),
      },
      {
        path: "iconography",
        name: "iconography",
        meta: { title: "Gallery Laboratorium - iconography" },
        component: () => import("../views/Gallery2/iconography.vue"),
      },
    ],
  },
  {
    path: "/gallery-bibliotheca",
    name: "Gallery3",
    component: () => import("../views/Gallery3.vue"),
    children: [
      {
        path: "scene/:id",
        name: "Scenes",
        meta: { title: "Gallery Bibliotheca" },
        component: () => import("../views/Gallery3/scene.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
