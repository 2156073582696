<template>
  <div
    :class="{
      'page-container page--home': true,
      'cursor-pointer': !clicked.stage,
    }"
    @click="clickOnStage"
  >
    <div ref="circleContainer" class="circle-container">
      <div class="circle-child circle-0"></div>
      <div class="circle-child circle-1"></div>
      <div class="circle-child circle-2"></div>
      <div class="circle-child circle-3"></div>
    </div>
    <div
      ref="btnAboutUs"
      @click="clickAboutUs"
      class="btn-about-container btn-about-us"
    >
      <div class="btn-aboutus">
        <img
          src="@/assets/images/btn-aboutus-0.png"
          class="img-responsive"
          alt=""
        />
        <img
          src="@/assets/images/btn-aboutus-1.png"
          class="img-responsive rotating"
          alt=""
        />
        <img
          src="@/assets/images/btn-aboutus-2.png"
          class="img-responsive rotating-2x"
          alt=""
        />
      </div>
    </div>
    <div
      ref="btnAboutThis"
      @click="clickAboutThis"
      class="btn-about-container btn-about-this"
    >
      <div class="btn-aboutus">
        <img
          src="@/assets/images/btn-aboutus-0.png"
          class="img-responsive"
          alt=""
        />
        <img
          src="@/assets/images/btn-aboutus-1.png"
          class="img-responsive rotating"
          alt=""
        />
        <img
          src="@/assets/images/btn-aboutus-2.png"
          class="img-responsive rotating-2x"
          alt=""
        />
      </div>
    </div>
    <div ref="title" class="center-container">
      <div class="center-header">
        <img
          src="../assets/images/landing-title-0.svg"
          class="img-responsive img-landing-title"
          alt=""
        />
      </div>
      <div class="center-body">
        <img
          src="../assets/images/landing-title-1.svg"
          class="img-responsive img-landing-title"
          alt=""
        />
      </div>
    </div>
    <div ref="mainTitle" class="center-container main-title-container">
      <div class="center-body">
        <img
          src="../assets/images/title-base.png"
          class="img-responsive img-main-title"
          alt=""
        />
        <img
          v-for="(icon, i) in 10"
          :key="i"
          :ref="`mainTitleIcon-${i}`"
          :src="require(`../assets/images/title-icon-${i}.png`)"
          class="img-responsive img-main-title img-main-title-icon"
          alt=""
        />
      </div>
    </div>
    <div ref="adcLogo" class="adc-container text-center">
      <img
        src="../assets/images/AGD_ack_logo-white@2x.png"
        class="img-responsive img-adc-logo"
        alt=""
      />
      <div class="disclaimer">
        <p>
          香港藝術發展局全力支持藝術表達自由，本項目內容並不反映本局意見
        </p>
        <p>
          Hong Kong Arts Development Council fully supports freedom of artistic
          expression. The views and opinions expressed in this project do not
          represent the stand of the Council.
        </p>
      </div>
    </div>
    <div ref="reminder" class="reminder-container">
      <div class="reminder-content-container">
        <inline-svg
          class="icon-sound-svg"
          :src="require('../assets/images/icon-sound.svg')"
        />
        <div class="reminder-msg ml-3">請開啟音效展開游歷之旅</div>
      </div>
    </div>
    <div ref="corridor" class="corridor-container">
      <img
        v-if="$vuetify.breakpoint.mdAndUp"
        src="/Corridor_R0.gif"
        class="img-responsive"
        alt=""
      />
      <img v-else src="/Corridor_R0-mobile.gif" class="img-responsive" alt="" />
    </div>
    <div ref="overlay" class="overlay"></div>
    <template>
      <v-dialog
        v-model="dialog.aboutus"
        dark
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-btn class="btn-close-dialog" icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar flat dark color="black">
            
          </v-toolbar> -->
          <v-card-text>
            <DialogAboutusContent />
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <v-dialog
        v-model="dialog.aboutthis"
        dark
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-btn class="btn-close-dialog" icon dark @click="closeThisDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <!-- <v-toolbar flat dark color="black">
            
          </v-toolbar> -->
          <v-card-text>
            <DialogAboutThisContent />
          </v-card-text>

          <div style="flex: 1 1 auto;"></div>
        </v-card>
      </v-dialog>
    </template>

    <!-- <div ref="background" class="background"></div> -->
  </div>
</template>

<script>
import { gsap, Power2, Expo } from "gsap";

import HandleCameraOrbit from "@/vendor/decorators/HandleCameraOrbit";
import FullScreenInBackground from "@/vendor/decorators/FullScreenInBackground";
import Engine from "@/vendor/utils/engine";
import Stars from "@/vendor/objects/Stars";
import app from "@/vendor/HomeApp";
import DialogAboutusContent from "@/components/DialogAboutusContent.vue";
import DialogAboutThisContent from "@/components/DialogAboutThisContent.vue";

export default {
  name: "Home",
  data() {
    return {
      engine: null,
      clicked: {
        stage: false,
        aboutus: false,
        aboutthis: false,
      },
      dialog: {
        aboutus: false,
        aboutthis: false,
      },
    };
  },
  components: {
    DialogAboutusContent,
    DialogAboutThisContent,
  },
  mounted() {
    if (this.engine) this.engine.remove();
    document.querySelector("#starCanvas")?.remove();

    @FullScreenInBackground
    @HandleCameraOrbit({ x: 2, y: 3 }, 0.05)
    class CustomEngine extends Engine {}
    this.engine = new CustomEngine();

    const stars = new Stars();
    this.engine.add(stars);

    // Show
    this.engine.start();

    const tlShow = new gsap.timeline({
      delay: 0.2,
      onStart: () => {
        // lineGenerator.start();
      },
    });
    tlShow.set(this.$refs.title, { opacity: 0 });
    tlShow.set(this.$refs.mainTitle, { opacity: 0 });
    tlShow.set(this.$refs.adcLogo, { opacity: 0 });
    tlShow.set(this.$refs.reminder, { opacity: 0, display: "none" });
    // tlShow.set(this.$refs.btnAboutUs, { opacity: 1 });
    tlShow.to(this.$refs.overlay, { duration: 2, opacity: 0 }, 0);
    // tlShow.to(this.$refs.background, { duration: 2, y: -300 }, 0);
    tlShow.to(this.$refs.title, { duration: 3, opacity: 1 }, 1.2);
    // tlShow.to(this.$refs.btnAboutUs, { duration: 2, opacity: .6 }, 1.5);
    tlShow.to(this.$refs.adcLogo, { duration: 5, opacity: 1 }, 1.6);

    tlShow.fromTo(
      this.engine.lookAt,
      { y: -5 },
      { y: 0, duration: 5, ease: Power2.easeOut },
      0
    );
    // tlShow.add(text.show, 0);

    // Hide
    app.onHide((onComplete) => {
      const tlHide = new gsap.timeline();
      tlHide.to(this.engine.lookAt, {
        duration: 2,
        y: -6,
        ease: Expo.easeInOut,
      });
      // tlHide.add(text.hide, { duration: 0 });
      // tlHide.add(lineGenerator.stop);
      tlHide.to(
        this.$refs.overlay,
        { duration: 0.5, autoAlpha: 1, onComplete },
        "-=1.5"
      );
    });
  },
  destroyed() {
    // if(this.engine) this.engine.remove();
    // document.querySelector('#starCanvas')?.remove();
  },
  methods: {
    closeDialog() {
      this.dialog.aboutus = false;
      this.clicked.aboutus = false;
    },
    closeThisDialog() {
      this.dialog.aboutthis = false;
      this.clicked.aboutthis = false;
    },
    clickAboutUs() {
      if (this.clicked.stage) return;
      this.clicked.aboutus = true;
      this.dialog.aboutus = true;
    },
    clickAboutThis() {
      if (this.clicked.stage) return;
      this.clicked.aboutthis = true;
      this.dialog.aboutthis = true;
    },
    clickOnStage(e) {
      if (this.clicked.aboutus || this.clicked.aboutthis || this.clicked.stage)
        return;

      this.closeDialog();
      this.closeThisDialog();
      let vm = this;
      this.clicked.stage = true;
      this.$store.commit("updateOpeningLoop", true);

      this.$gtag.event("Start the Journey", {});

      let pos = { x: e.clientX, y: e.clientY };

      gsap.set(".circle-container", { x: pos.x, y: pos.y });

      const tlGetIn = new gsap.timeline();
      tlGetIn.set(".circle-child", { scale: 0.01, opacity: 0 });
      tlGetIn.to(
        ".circle-child",
        {
          duration: 1.2,
          opacity: 1,
          ease: Power2.easeOut,
          stagger: 0.3,
        },
        0
      );
      tlGetIn.to(
        ".circle-child",
        {
          duration: 8,
          scale: 2,
          ease: Expo.easeOut,
          stagger: 0.4,
        },
        0
      );
      tlGetIn.to(
        ".circle-child",
        {
          duration: 1,
          opacity: 0,
          ease: Power2.easeIn,
          stagger: 0.5,
        },
        3
      );

      tlGetIn.to(
        this.$refs.title,
        {
          duration: 3,
          opacity: 0,
          ease: Power2.easeOut,
        },
        4
      );
      tlGetIn.to(
        this.$refs.btnAboutUs,
        {
          duration: 3,
          opacity: 0,
          display: "none",
          ease: Power2.easeOut,
        },
        4.5
      );
      tlGetIn.to(
        this.$refs.btnAboutThis,
        {
          duration: 3,
          opacity: 0,
          display: "none",
          ease: Power2.easeOut,
        },
        4.8
      );
      tlGetIn.to(
        this.$refs.adcLogo,
        {
          duration: 3,
          opacity: 0,
          ease: Power2.easeOut,
        },
        4.5
      );
      tlGetIn.to(this.$refs.reminder, {
        duration: 2,
        opacity: 1,
        display: "flex",
        ease: Power2.easeOut,
      });
      tlGetIn.to(this.$refs.reminder, {
        delay: 4,
        duration: 2,
        opacity: 0,
        display: "none",
        ease: Power2.easeIn,
      });
      tlGetIn.to(
        this.$refs.corridor,
        {
          duration: 1,
          opacity: 1,
          ease: Power2.easeOut,
          onStart: () => {
            setTimeout(() => {
              tlGetIn.to(this.$refs.corridor, { opacity: 0, duration: 1 });
              vm.showTitle();
            }, 5400);
          },
        },
        "-=.5"
      );
    },
    showTitle() {
      var tlIcon = new gsap.timeline();
      var vm = this;
      tlIcon.set(".img-main-title-icon", { opacity: 0 });
      tlIcon.to(this.$refs.mainTitle, {
        opacity: 1,
        duration: 2,
        ease: Power2.easeOut,
      });

      // this.fadeInIcon(0);
      const iconDuration = 0.8;
      for (var i = 0; i < 8; i++) {
        tlIcon
          .to(
            this.$refs[`mainTitleIcon-${i}`],
            { opacity: 1, duration: iconDuration },
            i == 0 ? "-=0.5" : "-=0"
          )
          .to(
            this.$refs[`mainTitleIcon-${i}`],
            { opacity: 0, duration: iconDuration },
            "+=0.2"
          );
      }

      tlIcon.to(
        this.$refs.mainTitle,
        {
          opacity: 0,
          duration: iconDuration,
          ease: Power2.easeOut,
          onComplete: function() {
            vm.gotoNextPage();
          },
        },
        12
      );
      // tlIcon.to(
      //   this.$refs.overlay,
      //   {
      //     opacity: 1,
      //     duration: 2,
      //     delay: 0.5,
      //     ease: Power2.easeOut,
      //     onComplete: function() {
      //       vm.gotoNextPage();
      //     },
      //   },
      //   12
      // );
    },
    gotoNextPage() {
      this.$router.push({ name: "Ticket" });
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.reminder-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: center;
}
.reminder-content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: $color--brown;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
}

.reminder-msg {
  font-weight: 300;
}

.icon-sound-svg {
  width: 28px;
  height: 28px;
  fill: $color--brown;
}
.main-title-container {
  .img-main-title-icon {
    opacity: 0;
  }
  .img-main-title {
    max-width: 422px;
    width: 100%;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
  }
}
.corridor-container {
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
.circle-container {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
  left: 0;
  top: 0;
  margin-left: -250px;
  div {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    opacity: 0;
    border: 2px solid $color--brown;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.btn-about-container {
  position: absolute;

  opacity: 0.6;
  z-index: 9999;
  transition: opacity 0.5s ease-out;
  &:hover {
    opacity: 1;
  }
}

.btn-about-container.btn-about-us {
  top: 60vh;
  left: 23vw;
}
.btn-about-container.btn-about-this {
  top: 30vh;
  left: 73vw;
}
.btn-aboutus {
  width: 33px;
  height: 33px;
  position: relative;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.center-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center-header,
.center-body,
.center-footer {
  text-align: center;
}
.page-container {
  margin: auto;
}
.disclaimer {
  font-size: 0.6rem;
}

.adc-container {
  max-width: 400px;
  margin: auto;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
}
.img-adc-logo {
  max-width: 250px;
}

.img-landing-title {
  max-width: 430px;
}
.center-header {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 4rem;
  .img-landing-title {
    max-width: 320px;
  }
}
.center-body {
  padding-left: 2rem;
  padding-right: 2rem;
  .img-landing-title {
    max-width: 430px;
  }
}

.overlay {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: $color--bg;
  opacity: 1;
}

.background {
  background: $color--bg;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
</style>
