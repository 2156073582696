import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  // treeShake: true,
  theme: { 
    dark: true,
    themes: {
      light: {
        primary: '#C1B49A',
        secondary: '#5A4A42',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
    }
  },  
});
