import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";

import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

// import browserDetect from "vue-browser-detect-plugin";
// Vue.use(browserDetect);

import VueGtag from "vue-gtag";

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-6TPY79EYXE",
      params: {
        send_page_view: true,
      },
    },
    pageTrackerTemplate(to) {
      return {
        page_title: to.meta.title,
        page_path: to.path,
      };
    },
  },
  router
);

Vue.use(VuePlyr, {
  plyr: {},
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
